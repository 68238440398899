import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Globe, X, Send, Check } from 'lucide-react';
import { Shield, Gift, Users, TrendingUp, AlertTriangle, Headphones, ChevronDown, ChevronUp, User, Lock, CreditCard, Gamepad , Info } from 'lucide-react';
import { Home, Award,} from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'


const NewsletterNotification = ({ isVisible, onClose, onSubscribe }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simula una richiesta API
    await new Promise(resolve => setTimeout(resolve, 1500));

    onSubscribe(email);
    setIsSubmitting(false);
    setIsSubmitted(true);

    // Chiudi la notifica dopo un breve ritardo
    setTimeout(() => {
      onClose();
      setIsSubmitted(false);
      setEmail('');
    }, 2000);
  };

  if (!isVisible) return null;

  return (
    <div className="newsletter-notification">
      <button className="close-button" onClick={onClose} aria-label="Close">
        <X size={24} />
      </button>
      <h3>Subscribe to Our Newsletter</h3>
      <p>Stay updated with our latest offers and promotions!</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isSubmitting || isSubmitted}
        />
        <button 
          type="submit" 
          className={`submit-button ${isSubmitting ? 'submitting' : ''} ${isSubmitted ? 'submitted' : ''}`}
          disabled={isSubmitting || isSubmitted}
        >
          {!isSubmitting && !isSubmitted && (
            <>
              <span>Subscribe</span>
              <Send size={20} />
            </>
          )}
          {isSubmitting && <div className="spinner"></div>}
          {isSubmitted && <Check size={24} />}
        </button>
      </form>
    </div>
  );
};


const ExtraCashbackBanner = () => (
  <div className="extraCashbackWrapper">
    <p className="golos">Enjoy <span className="animated-gradient-text">Extra Cashback</span> on Your Bets</p>
    <div className="offersButtons">
      <div className="input-transparent"><span className="subText">Up to</span> 20%</div>
      <div><button type="button" className="btn btn-packdraw" onClick={() => window.open("https://roobet.com/?ref=jok3rkick", "_blank")}>CLAIM CASHBACK</button></div>
      <div className="disclaimerText">Terms may apply. Cashback rates vary.</div>
    </div>
  </div>
);

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const ContactForm = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simuliamo una richiesta API
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Qui puoi aggiungere la logica per inviare i dati del form
    console.log('Form submitted', { name, email });

    setIsSubmitting(false);
    setIsSubmitted(true);

    // Chiudi il modal dopo un breve delay
    setTimeout(() => {
      onClose();
    }, 1500);
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <h2>Contact Us</h2>
      <p>Leave your details and we'll get back to you if live chat is unavailable.</p>
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        disabled={isSubmitting || isSubmitted}
      />
      <input
        type="email"
        placeholder="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        disabled={isSubmitting || isSubmitted}
      />
      <button 
        type="submit" 
        className={`submit-button ${isSubmitting ? 'submitting' : ''} ${isSubmitted ? 'submitted' : ''}`}
        disabled={isSubmitting || isSubmitted}
      >
        {!isSubmitting && !isSubmitted && (
          <>
            <span>Send</span>
            <Send size={20} />
          </>
        )}
        {isSubmitting && <div className="spinner"></div>}
        {isSubmitted && <Check size={24} />}
      </button>
    </form>
  );
};



const RegistrationSteps = () => {
  const sectionRef = useRef(null);
  const stepsRef = useRef([]);

  useEffect(() => {
    const section = sectionRef.current;
    const steps = stepsRef.current;

    const handleScroll = () => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const sectionProgress = (scrollY - sectionTop + windowHeight) / (sectionHeight + windowHeight);

      steps.forEach((step, index) => {
        const stepProgress = (sectionProgress - index / steps.length) * steps.length;
        if (stepProgress > 0 && stepProgress < 1) {
          step.style.opacity = stepProgress;
          step.style.transform = `translateY(${(1 - stepProgress) * 50}px)`;
        } else if (stepProgress >= 1) {
          step.style.opacity = 1;
          step.style.transform = 'translateY(0)';
        } else {
          step.style.opacity = 0;
          step.style.transform = 'translateY(50px)';
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const steps = [
    { 
      icon: <User size={40} />,
      title: "Create Your Account", 
      description: "Sign up with your email. Choose a unique username and a strong password to secure your account.",
      details: "Ensure your email is valid as you'll need to verify it."
    },
    { 
      icon: <CreditCard size={40} />,
      title: "Make Your First Deposit", 
      description: "Choose your preferred cryptocurrency and make your first deposit to start playing.",
      details: "Roobet supports various cryptocurrencies including Bitcoin, Ethereum, and Litecoin."
    },
    { 
      icon: <Gamepad size={40} />,
      title: "Start Your Gaming Adventure", 
      description: "Explore the wide variety of games and start your Roobet adventure!",
      details: "From slots to table games, and unique crypto games like Crash, there's something for everyone."
    }
  ];

  return (
    <section className="registration-steps" ref={sectionRef}>
      <h2>Your Journey Begins Here</h2>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className="step" 
            ref={(el) => (stepsRef.current[index] = el)}
          >
            <div className="step-content">
              <div className="step-icon">{step.icon}</div>
              <div className="step-number">{index + 1}</div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
              <div className="step-details">{step.details}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const FAQ = ({ questions }) => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    if (openQuestion === index) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(index);
    }
  };

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      {questions.map((question, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleQuestion(index)}>
            <h3>{question.question}</h3>
            {openQuestion === index ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </div>
          {openQuestion === index && <p className="faq-answer">{question.answer}</p>}
        </div>
      ))}
    </div>
  );
};


const MobileMenu = ({ isOpen, onClose, navigateTo }) => {
  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      <button className="close-menu" onClick={onClose}>×</button>
      <div className="mobile-menu-links">
        <a onClick={() => { navigateTo('home'); onClose(); }}>Home</a>
        <a onClick={() => { navigateTo('leaderboard'); onClose(); }}>Leaderboard</a>
        <a onClick={() => { navigateTo('about'); onClose(); }}>About Promo Site</a>
      </div>
    </div>
  );
};




const FloatingImage = ({ src, alt, style }) => (
  <div className="floating-image" style={style}>
    <img src={src} alt={alt} />
    <style jsx>{`
      .floating-image {
        position: absolute;
        animation: float 6s ease-in-out infinite;
      }
      .floating-image img {
        width: 100px;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.2);
      }
      @keyframes float {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-20px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    `}</style>
  </div>
);


const AffiliateNotification = ({ isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
        window.location.href = 'https://x.com/home';
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div className="affiliate-notification">
      <p>link connect.</p>
    </div>
  );
};

const LoadingPage = ({ language }) => (
  <div className="loading-page">
    <div className="loading-content">
      <div className="loading-dots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <p className="loading-text">{language === 'it' ? 'Caricamento...' : 'Loading...'}</p>
      <p className="loading-info">
        {language === 'it' 
          ? 'La pagina utilizza script complessi. Il caricamento potrebbe richiedere più tempo del previsto.'
          : 'This page uses complex scripts. Loading might take longer than usual.'}
      </p>
    </div>
    <div className="powered-by">
      <span>Powered by</span>
      <img src="/icon.png" alt="" />
    </div>
  </div>
);

const AboutPage = () => {
  const faqQuestions = [
    {
      question: "Is Roobet legal?",
      answer: "Roobet operates under a Curacao eGaming license. However, it's crucial to check if online gambling is legal in your jurisdiction before playing. Some countries have strict regulations or outright bans on online gambling."
    },
    {
      question: "How do I deposit on Roobet?",
      answer: "Roobet accepts various cryptocurrencies. To deposit, go to the deposit section, choose your preferred cryptocurrency, and follow the instructions to transfer funds to your account. Common options include Bitcoin, Ethereum, and Litecoin."
    },
    {
      question: "Are there any bonuses for new players?",
      answer: "While Roobet doesn't typically offer traditional welcome bonuses, they provide various promotions, rakeback, and rewards programs that benefit both new and existing players. Check their promotions page for current offers."
    },
    {
      question: "How does the RooWards program work?",
      answer: "RooWards is Roobet's loyalty program. As you play, you earn points that help you level up. Each level comes with its own set of rewards, which may include rakeback, free spins, or bonus funds. The more you play, the higher your level and the better the rewards."
    },
    {
      question: "Is Roobet provably fair?",
      answer: "Yes, Roobet uses provably fair technology for many of its games. This means you can verify the fairness of each game outcome. Roobet provides tools and information on how to check the fairness of your bets."
    },
    {
      question: "What games are available on Roobet?",
      answer: "Roobet offers a wide variety of games including slots, table games, live casino games, and unique crypto games. Popular options include Crash, Roulette, Blackjack, Mines, and a large selection of slot titles from various providers."
    },
    {
      question: "How can I withdraw my winnings?",
      answer: "Withdrawals on Roobet are processed using the same cryptocurrency methods available for deposits. Go to the withdrawal section, choose your preferred crypto, enter the amount and your wallet address, then confirm the transaction. Processing times may vary depending on the cryptocurrency network."
    },
    {
      question: "Does Roobet have a mobile app?",
      answer: "Roobet doesn't have a dedicated mobile app, but their website is fully optimized for mobile devices. You can access all features and games directly from your mobile browser for a seamless gaming experience on smartphones and tablets."
    }
  ];

  return (
    <div className="about-page">
      <div className="about-hero">
        <div className="hero-content">
          <h1 className="about-title">Discover Roobet</h1>
          <p className="about-subtitle">Your Guide to the Exciting World of Crypto Gaming</p>
        </div>
      </div>
      <div className="about-content">
        <section className="intro-section">
          <h2>What is Roobet?</h2>
          <p>Roobet is a leading crypto casino platform that has taken the online gambling world by storm. Known for its innovative approach, wide game selection, and community-driven features, Roobet offers a unique and exciting gaming experience for crypto enthusiasts.</p>
        </section>
        
        <div className="feature-grid">
          <div className="feature">
            <Shield size={32} />
            <h3>Crypto-Powered Gaming</h3>
            <p>Roobet embraces cryptocurrencies, offering fast transactions, enhanced privacy, and global accessibility. Popular options include Bitcoin, Ethereum, and Litecoin.</p>
          </div>
          <div className="feature">
            <Gift size={32} />
            <h3>Diverse Game Selection</h3>
            <p>From classic slots to innovative crash games, Roobet boasts a wide array of options. Enjoy popular titles like Towers, Mines, and Roobet Roulette.</p>
          </div>
          <div className="feature">
            <Users size={32} />
            <h3>Vibrant Community</h3>
            <p>Engage with fellow players through live chat, participate in tournaments, and follow your favorite streamers who play on Roobet.</p>
          </div>
          <div className="feature">
            <TrendingUp size={32} />
            <h3>Provably Fair Gaming</h3>
            <p>Roobet utilizes blockchain technology to ensure game fairness. Players can verify the randomness of each game outcome.</p>
          </div>
          <div className="feature">
            <AlertTriangle size={32} />
            <h3>Responsible Gaming</h3>
            <p>Roobet promotes responsible gambling with features like self-exclusion, deposit limits, and resources for those seeking help.</p>
          </div>
          <div className="feature">
            <Headphones size={32} />
            <h3>24/7 Support</h3>
            <p>Get assistance anytime with Roobet's round-the-clock customer support team, available via live chat and email.</p>
          </div>
        </div>

        <section className="rewards-section">
          <h2>Roobet Rewards</h2>
          <div className="rewards-content">
            <div className="reward-item">
              <h3>Rakeback</h3>
              <p>Earn a percentage of your wagers back, regardless of win or loss. The more you play, the more you earn!</p>
            </div>
            <div className="reward-item">
              <h3>RooWards</h3>
              <p>Level up your account to unlock exclusive perks, bonuses, and special promotions tailored just for you.</p>
            </div>
            <div className="reward-item">
              <h3>VIP Program</h3>
              <p>High-rollers can enjoy personalized service, higher limits, and luxurious perks in Roobet's exclusive VIP program.</p>
            </div>
          </div>
        </section>

        <RegistrationSteps />

      <FAQ questions={faqQuestions} />


        <div className="disclaimer">
          <p><strong>Disclaimer:</strong> This website is not affiliated with or endorsed by Roobet. We provide informational content about online casinos and gambling platforms. Always verify information on the official Roobet website and gamble responsibly. Be aware of the risks involved and never gamble more than you can afford to lose.</p>
        </div>
      </div>
    </div>
  );
};
const Leaderboard = () => {
  const [timeLeft, setTimeLeft] = useState(15 * 24 * 60 * 60);

  const totalPrizePool = 5000;
  const leaderboardData = [
    { rank: 1, username: "Player 1", wagered: 1000000, prize: 1500 },
    { rank: 2, username: "Player 2", wagered: 900000, prize: 1000 },
    { rank: 3, username: "Player 3", wagered: 800000, prize: 750 },
    { rank: 4, username: "Player 4", wagered: 700000, prize: 500 },
    { rank: 5, username: "Player 5", wagered: 600000, prize: 400 },
    { rank: 6, username: "Player 6", wagered: 500000, prize: 300 },
    { rank: 7, username: "Player 7", wagered: 400000, prize: 250 },
    { rank: 8, username: "Player 8", wagered: 300000, prize: 150 },
    { rank: 9, username: "Player 9", wagered: 200000, prize: 100 },
    { rank: 10, username: "Player 10", wagered: 100000, prize: 50 },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (24 * 60 * 60));
    const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = time % 60;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="leaderboard-page">
      <h1>Montly Leaderboard</h1>
      <div className="timer">Time left: {formatTime(timeLeft)}</div>
      <FloatingImage 
  src="https://www.hacksawgaming.com/images/casino_type_thumbnails/ghost.png" 
  alt="Ghost" 
  style={{ top: '10%', left: '5%' }}
/>
<FloatingImage 
  src="https://www.machineslotonline.it/wp-content/uploads/2023/05/rip-city-ross.webp" 
  alt="Rip City" 
  style={{ top: '20%', right: '5%' }}
/>
<FloatingImage 
  src="https://www.machineslotonline.it/wp-content/uploads/2023/03/the-dog-house-multihold-HS1.webp" 
  alt="Dog House" 
  style={{ bottom: '10%', left: '10%' }}
/>
      <div className="lb-podium">
        {leaderboardData.slice(0, 3).map((entry) => (
          <div key={entry.rank} className={`entry ${entry.rank === 1 ? 'gold' : entry.rank === 3 ? 'bronze' : ''}`}>
            <div className="profileWrapper">
              <div className="profilePictureWrapper">
                <div className="profilePicture"></div>
                <div className="profileBadgePosition"></div>
              </div>
              <p>{entry.username}</p>
            </div>
            <div className="wageredWrapper">
              <p className="wageredValue">$ {entry.wagered.toLocaleString()}</p>
              <p className="wageredText">Wagered</p>
            </div>
            <div className="prizeWrapper">
              <div className="prize">$ {entry.prize.toLocaleString()}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="leaderboard-list">
        <h2>Top Players</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Username</th>
                <th>Wagered</th>
                <th>Prize</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((entry) => (
                <tr key={entry.rank}>
                  <td>
                    <span className="rank">{entry.rank}</span>
                  </td>
                  <td>
                    <span className="username">{entry.username}</span>
                  </td>
                  <td>
                    <span className="wagered">${entry.wagered.toLocaleString()}</span>
                  </td>
                  <td>
                    <span className="prize">${entry.prize.toLocaleString()}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <style jsx>{`
          .leaderboard-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Rubik', sans-serif;
    position: relative;
    overflow: hidden;
  }
        
        .leaderboard-page h1 {
          font-size: 36px;
          font-weight: 800;
          margin-bottom: 20px;
          text-align: center;
          color: #fff;
          text-transform: uppercase;
        }
        
        .timer {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 30px;
          color: #886cff;
        }
        
        .leaderboard-list {
          background: linear-gradient(135deg, #1a1d2c, #2c3143);
          border-radius: 10px;
          padding: 20px;
          margin-top: 20px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        
        .leaderboard-list h2 {
          font-size: 24px;
          margin-bottom: 15px;
          color: #fff;
          text-transform: uppercase;
        }
        
        .table-container {
          overflow-x: auto;
        }
        
        .leaderboard-list table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 10px;
        }
        
        .leaderboard-list th, .leaderboard-list td {
          padding: 15px;
          text-align: left;
        }
        
        .leaderboard-list th {
          font-weight: 600;
          color: #886cff;
          text-transform: uppercase;
          font-size: 14px;
          border-bottom: 2px solid #886cff;
        }
        
        .leaderboard-list tbody tr {
          background: rgba(52, 73, 94, 0.5);
          transition: all 0.3s ease;
        }
        
        .leaderboard-list tbody tr:hover {
          background: rgba(136, 108, 255, 0.2);
          transform: translateY(-2px);
        }
        
        .leaderboard-list td {
          color: #ecf0f1;
        }
        
        .rank {
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: linear-gradient(135deg, #886cff, #582cff);
          border-radius: 12%;
          font-weight: bold;
          color: #fff;
        }
        
        .username {
          font-weight: 600;
          color: #fff;
        }
        
        .wagered {
          color: #00ff9d;
        }
        
        .prize {
          font-weight: 600;
          color: #222;
        }
        
        @media (max-width: 768px) {
          .leaderboard-list th, .leaderboard-list td {
            padding: 10px;
          }
          
          .rank {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
};

const Footer = ({ navigateTo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Responsible Gaming</h3>
          <p>At Jkr site, we promote responsible gaming. If you feel you may have a gambling problem, please visit our Responsible Gaming page for resources and support.</p>
          <a href="#" className="responsible-gaming-link" onClick={(e) => {
            e.preventDefault();
            navigateTo('terms');
          }}>Learn More</a>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#" onClick={(e) => {
              e.preventDefault();
              navigateTo('terms');
            }}>Terms of Service</a></li>
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">FAQ</a></li>
            <li><a href="#" onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}>Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 nocta llc. All rights reserved.</p>
        <div className="footer-buttons">
          
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ContactForm onClose={() => setIsModalOpen(false)} />
      </Modal>
    </footer>
  );
};

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-header"></div>
    <div className="skeleton-text"></div>
    <div className="skeleton-text"></div>
    <div className="skeleton-text"></div>
  </div>
);



const CookiePopup = ({ onAccept }) => {
  return (
    <div className="cookie-popup">
      <p>We use cookies to enhance your browsing experience and analyze our traffic. By clicking "Accept", you consent to our use of cookies.</p>
      <button onClick={onAccept}>Accept</button>
    </div>
  );
};

const TermsAndConditions = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    if (openSection === index) {
      setOpenSection(null);
    } else {
      setOpenSection(index);
    }
  };

  const termsContent = [
    {
      title: "1. Introduction and Acceptance of Terms",
      content: "Welcome to our affiliate website ('the Site'). By accessing or using our Site, you agree to be bound by these Terms and Conditions. These terms apply to all visitors, users, and others who access or use the Site. If you disagree with any part of the terms, you may not access the Site."
    },
    {
      title: "2. Affiliate Disclaimer",
      content: "This Site is an independent affiliate marketing site and is not owned, operated, endorsed, or affiliated with Roobet or any other online casino or gambling platform mentioned on this Site. We provide informational and promotional content about online gambling services, but we do not offer any gambling services ourselves."
    },
    {
      title: "3. Age Restriction and Legal Compliance",
      content: "Our Site is intended for informational purposes only and is not to be used by individuals under the legal gambling age in their jurisdiction. Users are responsible for ensuring that online gambling is legal in their jurisdiction before engaging with any services promoted on this Site."
    },
    {
      title: "4. Accuracy of Information",
      content: "While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information, products, services, or related graphics contained on the Site for any purpose."
    },
    {
      title: "5. Affiliate Links and Compensation",
      content: "Our Site contains affiliate links. If you click on these links and sign up or make a purchase, we may receive a commission. This commission comes at no additional cost to you and helps support the operation of this Site. All affiliate relationships are disclosed in accordance with FTC guidelines."
    },
    {
      title: "6. Responsible Gambling",
      content: "We strongly advocate for responsible gambling. If you or someone you know has a gambling problem, we urge you to seek help. Our Site provides information about responsible gambling practices and resources for those seeking assistance with gambling-related issues."
    },
    {
      title: "7. Privacy Policy",
      content: "Your use of the Site is also governed by our Privacy Policy. Please review our Privacy Policy to understand our practices regarding data collection and usage."
    },
    {
      title: "8. Limitation of Liability",
      content: "In no event shall we be liable for any direct, indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of the Site or any content, services, or products obtained through the Site."
    },
    {
      title: "9. Changes to Terms",
      content: "We reserve the right to modify these terms at any time. We will always post the most current version on our Site. By continuing to use the Site after changes have been made, you agree to be bound by the revised terms."
    },
    {
      title: "10. Governing Law",
      content: "These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions."
    }
  ];

  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      {termsContent.map((section, index) => (
        <div key={index} className="terms-section">
          <h2 onClick={() => toggleSection(index)}>
            {section.title}
            {openSection === index ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </h2>
          {openSection === index && <p>{section.content}</p>}
        </div>
      ))}
    </div>
  );
};

const RoobetApp = () => {
  const [language, setLanguage] = useState('en');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState('home');
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [showAffiliateNotification, setShowAffiliateNotification] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showNewsletterNotification, setShowNewsletterNotification] = useState(false);

  const smoothScroll = (target) => {
    const element = document.querySelector(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const handleNewsletterSubscribe = (email) => {
    console.log(`Subscribed with email: ${email}`);
    // Qui puoi aggiungere la logica per inviare l'email al tuo server
    setShowNewsletterNotification(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNewsletterNotification(true);
    }, 10000); // Mostra la notifica dopo 10 secondi

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.intercom.io/widget/o2h1erjg';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.Intercom('boot', {
        app_id: 'o2h1erjg'
      });
    };

    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => {
      document.body.removeChild(script);
      clearTimeout(timer);
    };
  }, []);

  const navigateTo = (page) => {
    setCurrentPage(page);
  };

  const acceptCookies = () => {
    setShowCookiePopup(false);
    // Here you would typically set a cookie or local storage item
    // to remember that the user has accepted cookies
  };

  const handleAffiliateClick = () => {
    setShowAffiliateNotification(true);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>

     
      <style jsx global>{`

.roobetLB {
  position: relative;
}

      .newsletter-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2c3143;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-notification h3 {
  font-size: 20px;
  margin-bottom: 12px;
  color: #fff;
  text-align: center;
}

.newsletter-notification p {
  font-size: 14px;
  margin-bottom: 16px;
  color: #ccc;
  text-align: center;
}

.newsletter-notification form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-notification input {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #3a3f55;
  border-radius: 4px;
  background: #1a1d2c;
  color: #fff;
  font-size: 14px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background: #5865F2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button:hover:not(:disabled) {
  background-color: #4752C4;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-button.submitting {
  background-color: #4752C4;
}

.submit-button.submitted {
  background-color: #43B581;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.6s ease-out;
}

      .extraCashbackWrapper {
        align-items: center;
        background: linear-gradient(135deg, #1a1d2c, #2c3143);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px;
        width: 100%;
        height: 222px;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;
      }
      
      .extraCashbackWrapper::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        z-index: 0;
      }
      
      .extraCashbackWrapper > * {
        position: relative;
        z-index: 1;
      }
      
      .extraCashbackWrapper p {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
      }
      
      .extraCashbackWrapper .offersButtons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 350px;
        text-align: center;
        width: 100%;
      }
      
      .animated-gradient-text {
        background: linear-gradient(270deg, #886cff, #582cff, #ff00ff, #886cff);
        background-size: 300% 300%;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: gradientAnimation 6s ease infinite;
        display: inline-block;
      }
      
      @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }

      .input-transparent {
        background: rgba(238, 239, 239, 0.05);
        border: 1px solid rgba(238, 239, 239, 0.05);
        border-radius: 6px;
        font-size: 16px;
        padding: 11px 22px;
        text-align: center;
        width: 100%;
        color: #fff;
      }

      .subText {
        color: rgba(238, 239, 239, 0.5);
        margin-right: 5px;
      }

      .btn-packdraw {
        background: linear-gradient(277deg, #6707c7 2.45%, #c240ff 98.78%);
        border: 1px solid #c03ffe;
        border-radius: 8px;
        box-shadow: 0 4px 0 0 #6707c7;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        padding: 11px 22px;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
        text-transform: uppercase;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      }

      .btn-packdraw:hover {
        transform: translateY(-2px);
        box-shadow: 0 7px 14px rgba(103, 7, 199, 0.3);
      }

      .disclaimerText {
        color: rgba(255, 255, 255, 0.25);
        font-size: 12px;
      }

      @media (max-width: 768px) {
        .extraCashbackWrapper {
          height: auto;
          padding: 20px;
        }

        .extraCashbackWrapper p {
          font-size: 18px;
        }

        .input-transparent, .btn-packdraw {
          font-size: 14px;
          padding: 10px 18px;
        }
      }

      

      .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: #1a1d2c;
          padding: 30px;
          border-radius: 6px;
          position: relative;
          max-width: 500px;
          width: 90%;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
        }

        .modal-close {
          position: absolute;
          top: 15px;
          right: 15px;
          background: none;
          border: none;
          color: #886cff;
          cursor: pointer;
          padding: 5px;
          transition: color 0.3s ease;
        }

        .modal-close:hover {
          color: #fff;
        }

        .contact-form {
          display: flex;
          flex-direction: column;
        }

        .contact-form h2 {
          color: #886cff;
          margin-bottom: 15px;
          font-size: 24px;
        }

        .contact-form p {
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 25px;
          font-size: 16px;
          line-height: 1.5;
        }

        .contact-form input {
          margin-bottom: 20px;
          padding: 12px;
          border-radius: 4px;
          border: 1px solid rgba(136, 108, 255, 0.3);
          background-color: rgba(255, 255, 255, 0.05);
          color: #fff;
          font-size: 16px;
          transition: border-color 0.3s ease, background-color 0.3s ease;
        }

        .contact-form input:focus {
          outline: none;
          border-color: #886cff;
          background-color: rgba(255, 255, 255, 0.1);
        }

        .contact-form button {
          padding: 12px;
          background-color: #886cff;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          transition: background-color 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }

        .contact-form button:hover:not(:disabled) {
          background-color: #7a5cf7;
        }

        .contact-form button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .submit-button.submitting {
          background-color: #7a5cf7;
        }

        .submit-button.submitted {
          background-color: #4CAF50;
        }

        .spinner {
          width: 20px;
          height: 20px;
          border: 2px solid #ffffff;
          border-top: 2px solid transparent;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

        .page-transition-enter {
    opacity: 0;
  }

  .page-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .page-transition-exit {
    opacity: 1;
  }

  .page-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }

.registration-steps {
          padding: 60px 20px;
          background: linear-gradient(135deg, #1a1d2c, #2c3143);
          border-radius: 15px;
          margin-bottom: 40px;
          overflow: hidden;
        }

        .registration-steps h2 {
          text-align: center;
          font-size: 28px;
          color: #886cff;
          margin-bottom: 40px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        .steps-container {
          max-width: 800px;
          margin: 0 auto;
          position: relative;
        }

        .steps-container::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 20px;
          width: 4px;
          background: rgba(136, 108, 255, 0.3);
        }

        .step {
          margin-bottom: 60px;
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }

        .step:last-child {
          margin-bottom: 0;
        }

        .step-content {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 15px;
          padding: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          position: relative;
          z-index: 1;
          margin-left: 45px;
        }

        .step-icon {
          position: absolute;
          top: 25%;
          left: -45px;
          transform: translateY(-50%);
          background: #886cff;
          border-radius: 24%;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          box-shadow: 0 5px 15px rgba(136, 108, 255, 0.3);
        }

        .step-number {
          position: absolute;
          top: 10px;
          right: 10px;
          background: #886cff;
          color: #fff;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 14px;
        }

        .step h3 {
          font-size: 20px;
          color: #fff;
          margin-bottom: 10px;
        }

        .step p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 10px;
        }

        .step-details {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          padding-top: 10px;
          margin-top: 10px;
        }

        @media (min-width: 768px) {
          .registration-steps {
            padding: 100px 40px;
          }

          .registration-steps h2 {
            font-size: 36px;
          }

          .steps-container::before {
            left: 50%;
            transform: translateX(-50%);
          }

          .step-content {
            margin-left: 0;
            padding: 30px;
          }

          .step-icon {
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
          }

          .step h3 {
            font-size: 24px;
          }

          .step p {
            font-size: 16px;
          }

          .step-details {
            font-size: 14px;
          }
        }


      @keyframes gradientAnimation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .roobet.golos.animated-gradient {
          background: linear-gradient(270deg, #886cff, #582cff, #ff00ff, #886cff);
          background-size: 300% 300%;
          animation: gradientAnimation 6s ease infinite;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }


      .vip-text {
  color: #FFD700; /* Colore giallo oro */
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.vip-text::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    top: -50%;
    left: -50%;
  }
  100% {
    top: 150%;
    left: 150%;
  }
}

      
       .roobetSideBySide > div .headerText h2 {
          text-align: center;
          font-size: 24px;
          margin-top: 0;
          margin-bottom: 20px;
          transform: translateY(-25%); /* Alza il testo del 25% */
          position: relative; /* Assicura che la trasformazione funzioni correttamente */
        }
      

      .subText {
          text-align: center;
          margin-bottom: 20px;
          margin-top: 10px; /* Aggiungiamo un margine superiore per abbassare leggermente il testo */
        }

        .subText p {
          margin: 0; /* Rimuoviamo il margine predefinito del paragrafo */
          padding-top: 10px; /* Aggiungiamo un leggero padding superiore per un ulteriore spaziatura */
        }
     .input-transparent {
          background: rgba(238, 239, 239, 0.05);
          border: 1px solid rgba(238, 239, 239, 0.05);
          border-radius: 6px;
          font-size: 16px;
          padding: 11px 22px;
          text-align: center;
          width: 100%;
          transform: translateY(-25%); /* Questa linea alza il banner del 25% */
          position: relative; /* Aggiungiamo questa linea per assicurarci che la trasformazione funzioni correttamente */
          z-index: 1; /* Aggiungiamo questa linea per mantenere il banner sopra altri elementi se necessario */
        }


       .hamburger-menu {
          display: none;
          flex-direction: column;
          justify-content: space-around;
          width: 30px;
          height: 25px;
          background: transparent;
          border: none;
          cursor: pointer;
          padding: 0;
          z-index: 10;
        }

        .hamburger-menu span {
          width: 30px;
          height: 3px;
          background: #fff;
          border-radius: 10px;
          transition: all 0.3s linear;
          position: relative;
          transform-origin: 1px;
        }

        .mobile-menu {
          position: fixed;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100%;
          background: #1a1d2c;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: transform 0.3s ease-in-out;
          transform: translateX(-100%);
          z-index: 1000;
        }

        .mobile-menu.open {
          transform: translateX(0);
        }

        .mobile-menu-links {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .mobile-menu-links a {
          color: #fff;
          text-decoration: none;
          font-size: 24px;
          margin: 20px 0;
        }

        .close-menu {
          position: absolute;
          top: 20px;
          right: 20px;
          background: none;
          border: none;
          font-size: 30px;
          color: #fff;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .home-bar-links,
          .home-bar-actions,
          .discord-login,
          .kick-login {
            display: none !important;
          }

          .hamburger-menu {
            display: flex;
          }

          .home-bar {
            justify-content: flex-end;
            padding: 12px 15px;
          }
        }
          .home-bar-logo {
  height: 40px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .home-bar-logo {
    height: 30px;
    margin-right: 10px;
  }
}
      .kick-login {
          background: linear-gradient(277deg, #2ecc71 2.45%, #27ae60 98.78%);
          border: 1px solid #27ae60;
          border-radius: 8px;
          box-shadow: 0 4px 0 0 #218c54;
          width: 30px;
          height: 30px;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 15px;
          right: 75px; /* Posizionato a destra del bottone Discord */
          overflow: hidden;
          transition: all 0.3s ease;
          z-index: 1000;
        }

        .kick-login:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 0 0 #218c54;
        }

        .kick-login:active {
          transform: translateY(2px);
          box-shadow: 0 2px 0 0 #218c54;
        }

        .kick-login::before {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(
            to bottom right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: rotate(45deg);
          animation: shine 3s infinite;
        }

        .kick-icon {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }

        @media (max-width: 768px) {
          .kick-login {
            width: 40px;
            height: 40px;
            top: 10px;
            right: 63px;
          }

          .kick-icon {
            width: 24px;
            height: 24px;
          }
        }
          
      .affiliate-notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: #2c3143;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: slideUp 0.3s ease-out;
}

.affiliate-notification p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
        .btn-packdraw {
  background: linear-gradient(277deg, #6707c7 2.45%, #c240ff 98.78%);
  border: 1px solid #c03ffe;
  border-radius: 8px;
  box-shadow: 0 4px 0 0 #6707c7;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 11px 22px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-packdraw:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 0 0 #6707c7;
}

.btn-packdraw:active {
  transform: translateY(2px);
  box-shadow: 0 2px 0 0 #6707c7;
}

@media (max-width: 768px) {
  .roobetSideBySide > div .offersButtons .btn-packdraw {
    font-size: 12px;
    padding: 8px 12px;
    width: auto;
    min-width: 120px;
    max-width: 150px;
    margin: 0 auto;
  }

  .roobetSideBySide > div {
    padding: 15px 10px;
  }

  .roobetSideBySide > div .offersButtons {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .roobetSideBySide > div .input-transparent {
    font-size: 14px;
    padding: 8px 12px;
    margin-bottom: 10px;
  }

  .roobetSideBySide > div .disclaimerText {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }
}
.language-selector {
          position: relative;
          display: inline-block;
        }

        .language-selector-btn {
          display: flex;
          align-items: center;
          background: linear-gradient(277deg, #1a1d2c 2.45%, #2c3143 98.78%);
          border: 1px solid #3a3f55;
          border-radius: 8px;
          box-shadow: 0 4px 0 0 #0f1118;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          padding: 8px 12px;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .language-selector-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 0 0 #0f1118;
        }

        .language-selector-btn svg {
          margin-right: 6px;
        }

        .language-options {
          position: absolute;
          top: 100%;
          right: 0;
          background: #2c3143;
          border: 1px solid #3a3f55;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          display: none;
          z-index: 1000;
        }

        .language-selector:hover .language-options {
          display: block;
        }

        .language-option {
          padding: 8px 12px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .language-option:hover {
          background-color: #3a3f55;
        }

        @import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,800,900,300i,400i,500i,600i,700i,800i,900i&display=swap');

        body {
          color: #fff;
          font-family: Rubik, sans-serif;
          font-size: 1rem;
          margin: 0;
          padding: 0;
          background-color: #11141e;
          transition: background-color 0.3s, color 0.3s;
        }
        
        * {
          box-sizing: border-box;
          user-select: none;
        }
        
        .loading-page {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #11141e;
          position: relative;
        }
        
        .loading-content {
          text-align: center;
          max-width: 80%;
        }
        
        .loading-dots {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
        
        .dot {
          width: 8px;
          height: 8px;
          background-color: #fff;
          border-radius: 50%;
          animation: pulse 1.4s infinite ease-in-out;
        }
        
        .dot:nth-child(1) {
          animation-delay: -0.32s;
        }
        
        .dot:nth-child(2) {
          animation-delay: -0.16s;
        }
        
        @keyframes pulse {
          0%, 80%, 100% { 
            transform: scale(0);
            opacity: 0.5;
          } 
          40% { 
            transform: scale(1);
            opacity: 1;
          }
        }
        
        .loading-text {
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          margin-top: 15px;
          margin-bottom: 10px;
        }

        .loading-info {
          font-size: 12px;
          color: #a0a0a0;
          max-width: 300px;
          margin: 0 auto;
          line-height: 1.4;
        }

        .powered-by {
          position: absolute;
          bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #a0a0a0;
          font-size: 12px;
        }

        .powered-by span {
          margin-right: 8px;
        }

        .powered-by img {
          height: 20px;
          width: auto;
        }
        
        .discord-login {
          background: linear-gradient(277deg, #5865F2 2.45%, #7289DA 98.78%);
          border: 1px solid #7289DA;
          border-radius: 8px;
          box-shadow: 0 4px 0 0 #4752C4;
          color: #fff;
          width: 30px;
          height: 30px;
          padding: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 15px;
          right: 36px;
          overflow: hidden;
          transition: all 0.3s ease;
          z-index: 1000;
        }
        
        .discord-login:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 0 0 #4752C4;
        }
        
        .discord-login:active {
          transform: translateY(2px);
          box-shadow: 0 2px 0 0 #4752C4;
        }
        
        .discord-login::before {
          content: '';
          position: absolute;
          top: -50%;
          left: -50%;
          width: 200%;
          height: 200%;
          background: linear-gradient(
            to bottom right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 50%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: rotate(45deg);
          animation: shine 3s infinite;
        }
        
        @keyframes shine {
          0% {
            top: -50%;
            left: -50%;
          }
          100% {
            top: 150%;
            left: 150%;
          }
        }

        .discord-icon {
          width: 14px;
          height: 24px;
          fill: #fff;
          display: block;
          margin: auto;
        }

        @media (max-width: 768px) {
          .discord-login {
            width: 40px;
            height: 40px;
            top: 10px;
            right: 13px;
          }

          .discord-icon {
            width: 31px;
            height: 20px;
          }
        }

        .home-bar {
          background: linear-gradient(277deg, #1a1d2c 2.45%, #2c3143 98.78%);
          border: 1px solid #3a3f55;
          border-radius: 8px;
          box-shadow: 0 4px 0 0 #0f1118;
          padding: 12px 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 15px;
          width: calc(100% - 30px);
          max-width: 1170px;
          margin: 15px auto;
          transition: all 0.3s ease;
        }

        .home-bar:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 0 0 #0f1118;
        }

        .home-bar-links a {
          color: #fff;
          text-decoration: none;
          font-weight: 600;
          margin: 0 15px;
          transition: color 0.3s;
          cursor: pointer;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
        }

        .home-bar-links a:hover {
          color: #7289DA;
        }
        
        .home-bar-links {
          display: flex;
        }
        
        .home-bar-actions {
          display: flex;
          align-items: center;
        }
        
        .discord-icon {
          width: 12px;
          height: 20px;
          margin-right: 8px;
          fill: #fff;
        }
        
.about-page {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          color: #fff;
          font-family: 'Rubik', sans-serif;
        }
        
        .about-hero {
          background: linear-gradient(135deg, rgba(136, 108, 255, 0.9), rgba(88, 44, 255, 0.9)),
                      url('https://smartcasinoguide.com/app/uploads/2023/05/roobet-logo.png') no-repeat center center;
          background-size: cover;
          border-radius: 15px;
          padding: 60px 30px;
          text-align: center;
          margin-bottom: 40px;
          position: relative;
          overflow: hidden;
  background-attachment: fixed;
  transition: background-position 0.5s ease-out;
}
        
        
        .about-hero::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: inherit;
          filter: blur(5px);
          opacity: 0.7;
          z-index: 1;
        }
        
        .hero-content {
          position: relative;
          z-index: 2;
        }
        
        .about-title {
          font-size: 48px;
          font-weight: 800;
          margin-bottom: 10px;
          color: #fff;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
        
        .about-subtitle {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.9);
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }
        
        .about-content {
          background: linear-gradient(135deg, #1a1d2c, #2c3143);
          border-radius: 15px;
          padding: 40px;
        }
        
        .intro-section {
          margin-bottom: 40px;
        }
        
        .intro-section h2 {
          font-size: 32px;
          margin-bottom: 20px;
          color: #886cff;
        }
        
        .intro-section p {
          font-size: 18px;
          line-height: 1.6;
        }
        
        .feature-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 30px;
          margin-bottom: 40px;
        }
        
        .feature {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 30px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        
        .feature:hover {
          transform: translateY(-5px);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        }
        
        .feature svg {
          color: #886cff;
          margin-bottom: 20px;
        }
        
        .feature h3 {
          font-size: 22px;
          margin-bottom: 15px;
          color: #fff;
        }
        
        .feature p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
        }
        
        .rewards-section, .faq-section {
          margin-bottom: 40px;
        }
        
        .rewards-section h2, .faq-section h2 {
          font-size: 32px;
          margin-bottom: 30px;
          color: #886cff;
        }

       .faq-item {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          margin-bottom: 15px;
          overflow: hidden;
        }
        
        .faq-question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        
        .faq-question:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        
        .faq-question h3 {
          font-size: 18px;
          margin: 0;
          color: #fff;
        }
        
        .faq-answer {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          padding: 0 20px 20px;
          line-height: 1.6;
        }
        
        .rewards-content {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
        }
        
        .reward-item, .faq-item {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 25px;
        }
        
        .reward-item h3, .faq-item h3 {
          font-size: 20px;
          margin-bottom: 15px;
          color: #fff;
        }
        
        .reward-item p, .faq-item p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
        }
        
        .disclaimer {
          background: rgba(255, 255, 255, 0.05);
          border-radius: 12px;
          padding: 20px;
          margin-top: 40px;
        }
        
        .disclaimer p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
        }
        
        @media (max-width: 768px) {
          .about-title {
            font-size: 36px;
          }
          
          .about-subtitle {
            font-size: 18px;
          }
          
          .feature-grid {
            grid-template-columns: 1fr;
          }
          
          .rewards-content {
            grid-template-columns: 1fr;
          }
        }
        
        .leaderboard-page {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
        }
        
        .leaderboard-page h1 {
          font-size: 36px;
          font-weight: 800;
          margin-bottom: 20px;
          text-align: center;
        }
        
        .timer {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 30px;
        }
        
        .lb-podium {
          display: flex;
          flex-direction: row;
          gap: 15px;
          justify-content: center;
          padding-top: 90px;
          margin-bottom: 50px;
        }
        
        .entry {
          background: #212432 radial-gradient(circle at 50% 110%,#fff3,#0000 50%);
          border-radius: 15px;
          box-shadow: inset 0 1px 2px 0 #ffffff0d;
          display: flex;
          flex-direction: column;
          height: 300px;
          width: 22.5%;
          max-width: 260px;
          order: 1;
          padding: 0 20px;
          position: relative;
        }
        
        .entry:nth-child(2) {
          margin-top: -0px;
        }
        
        .entry.gold {
          background: #212432 radial-gradient(circle at 50% 110%,#efb35133,#0000 50%);
          order: 2;
        }
        
        .entry.bronze {
          background: #212432 radial-gradient(circle at 50% 110%,#e9857033,#0000 50%);
          order: 3;
        }
        
        .entry .profileWrapper {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        
        .entry .prizeWrapper {
          margin-top: 20px;
        }
        
        .floating {
          animation: floating 2.5s ease-in-out infinite;
        }
        
        .entry .winnerCrown {
          left: calc(50% - 23px);
          position: absolute;
          top: -31px;
          width: 46px;
          height: 46px;
          background-color: #efb351;
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
        }
        
        .entry .profileWrapper .profilePictureWrapper {
          padding: 25px 0 10px;
          position: relative;
        }
        
        .entry .profileWrapper p {
          color: #fff;
          display: block;
          font-size: 16px;
          font-weight: 500;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          margin: 10px 0;
        }
        
        .entry .wageredWrapper > p {
          margin: 0;
          text-align: center;
        }
        
        .entry .wageredWrapper .wageredValue {
          color: #fff;
          font-size: 18px;
          font-weight: 600;
        }
        
        .entry .wageredWrapper .wageredText {
          color: #b2b2b254;
          font-size: 14px;
        }
        
        .entry .prizeWrapper .prize {
          background: linear-gradient(270deg,#bfbfbf,#7f7f7f);
          border: 1px solid #fff3;
          border-radius: 8px;
          box-sizing: border-box;
          font-size: 16px;
          font-weight: 600;
          height: 40px;
          line-height: 40px;
          text-align: center;
          width: 100%;
        }
        
        .entry.gold .prizeWrapper .prize {
          background: linear-gradient(270deg,#e9ae4b,#c19027);
        }
        
        .entry.bronze .prizeWrapper .prize {
          background: linear-gradient(270deg,#d6806b,#b2614a);
        }
        
        .entry .profileWrapper .profilePictureWrapper .profilePicture {
          border: 3px solid #b2b2b226;
          border-radius: 17%;
          padding: 3px;
          width: 90px;
          height: 90px;
          background-color: #333;
        }
        
        .entry .profileWrapper .profilePictureWrapper .profileBadgePosition {
          bottom: 8px;
          position: absolute;
          right: 0;
          width: 22px;
          height: 22px;
          background-color: #555;
          border-radius: 50%;
        }
        
        .leaderboard-list {
          background: #1a1d2c;
          border-radius: 10px;
          padding: 20px;
          margin-top: 20px;
        }
        
        .leaderboard-list h2 {
          font-size: 24px;
          margin-bottom: 15px;
        }
        
        .leaderboard-list table {
          width: 100%;
          border-collapse: collapse;
        }
        
        .leaderboard-list th, .leaderboard-list td {
          padding: 10px;
          text-align: left;
          border-bottom: 1px solid #2c3143;
        }
        
        .leaderboard-list th {
          font-weight: 600;
        }
        
.footer {
          background: #1a1d2c;
          color: #fff;
          padding: 40px 20px;
          margin-top: 60px;
        }
        
        .footer-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 1200px;
          margin: 0 auto;
        }
        
        .footer-section {
          flex: 1;
          margin-right: 20px;
          margin-bottom: 20px;
          min-width: 250px;
        }
        
        .footer-section h3 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        
        .footer-section p {
          font-size: 14px;
          line-height: 1.5;
        }
        
        .footer-section ul {
          list-style: none;
          padding: 0;
        }
        
        .footer-section ul li {
          margin-bottom: 10px;
        }
        
        .footer-section a {
          color: #fff;
          text-decoration: none;
        }
        
        .responsible-gaming-link {
          display: inline-block;
          margin-top: 10px;
          padding: 5px 10px;
          background: #2c3143;
          border-radius: 5px;
          font-size: 14px;
        }
        
        .footer-bottom {
          text-align: center;
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #2c3143;
        }
        
        .footer-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
        
        .footer-button {
          margin: 0 10px;
        }
        
        .footer-button img {
          height: 40px;
          width: auto;
        }
        
        @media (max-width: 768px) {
          .footer-content {
            flex-direction: column;
          }
          
          .footer-section {
            margin-right: 0;
          }
          
          .footer-buttons {
            flex-direction: column;
          }
          
          .footer-button {
            margin: 10px 0;
          }
        }

        
        .cookie-popup {
          position: fixed;
          bottom: 20px;
          left: 20px;
          right: 20px;
          background: #2c3143;
          border-radius: 10px;
          padding: 20px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          z-index: 1000;
        }
        
        .cookie-popup p {
          margin: 0;
          font-size: 14px;
        }
        
        .cookie-popup button {
          background: #5865F2;
          color: #fff;
          border: none;
          border-radius: 5px;
          padding: 8px 16px;
          font-size: 14px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        
        .cookie-popup button:hover {
          background-color: #4752C4;
        }
        
        @keyframes floating {
          0% { transform: translateY(0px); }
          50% { transform: translateY(10px); }
          100% { transform: translateY(0px); }
        }
        
        @media (max-width: 1200px) {
          .lb-podium {
            flex-direction: column;
            align-items: center;
            padding-top: 30px;
          }
          .entry {
            width: 80%;
            max-width: 300px;
            margin-bottom: 20px;
          }
          .entry:nth-child(2) {
            margin-top: 0;
          }
          .footer-content {
            flex-direction: column;
          }
          .footer-section {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        .terms-and-conditions {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          background: #1a1d2c;
          border-radius: 10px;
        }

        .terms-and-conditions h1 {
          font-size: 36px;
          font-weight: 800;
          margin-bottom: 20px;
          text-align: center;
        }

        .terms-section {
          margin-bottom: 20px;
          border-bottom: 1px solid #2c3143;
          padding-bottom: 20px;
        }

        .terms-section h2 {
          font-size: 24px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: color 0.3s;
        }

        .terms-section h2:hover {
          color: #5865F2;
        }

        .terms-section p {
          font-size: 16px;
          line-height: 1.6;
          margin-top: 10px;
        }

        .landingRoobetBonuses {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
        }
        
        .primaryWrapper {
          align-items: center;
          background: linear-gradient(135deg, #1a1d2c, #2c3143);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 25px;
          width: 100%;
          height: 385px;
          border-radius: 10px;
          margin-bottom: 15px;
          position: relative;
          overflow: hidden;
        }
        
        .primaryWrapper::before {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          bottom: 5px;
          background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://roobetfans.com/wp-content/uploads/2021/05/580x380-Roobet-GettingStarted-1.png');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
          z-index: 0;
        }
        
        .primaryWrapper > * {
          position: relative;
          z-index: 1;
        }
        
        .roobetSideBySide {
          display: flex;
          gap: 15px;
          width: 100%;
        }
        
        .golos {
          font-family: 'Golos Text', sans-serif !important;
        }
        
        .primaryWrapper p {
          font-size: 22px;
          font-weight: 600;
        }
        
        .primaryWrapper .offersButtons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-width: 350px;
          text-align: center;
          width: 100%;
        }
        
        .roobetSideBySide > div {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 25px;
          width: calc(50% - 7.5px);
          height: 520px;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
        }
        
        .roobetSideBySide .roobetVIP {
          background: linear-gradient(135deg, #2a2d3d, #3c3f52);
        }
        
        .roobetSideBySide .roobetLB {
          background: linear-gradient(135deg, #3d2a2a, #523c3c);
        }
        
        .roobetSideBySide > div::before {
          content: "";
          position: absolute;
          top: 5px;
          left: 5px;
          right: 5px;
          bottom: 5px;
          background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://lh5.googleusercontent.com/R5zK9lwIN9z8valyK1LIaHOYYk3eO4_0U-BW2SHMFtfdaT5s-hsdfQVmKET9ErNLLp51MuJtm3NAinEpD-yxjinJ5JR6F2waV362kP71YTGp-_-P6n7PVMS5LtsZw3KGm3_VTsNLVSv62HQOphaWh1k');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
          z-index: 0;
        }
        
        .roobetSideBySide > div > * {
          position: relative;
          z-index: 1;
        }
        
        .roobet {
          background: linear-gradient(90deg, #886cff, #802db2);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        .input-transparent {
          background: rgba(238, 239, 239, 0.05);
          border: 1px solid rgba(238, 239, 239, 0.05);
          border-radius: 6px;
          font-size: 16px;
          padding: 11px 22px;
          text-align: center;
          width: 100%;
        }
        
        .offersButtons .disclaimerText {
          color: rgba(255, 255, 255, 0.25);
        }
        
        .roobetSideBySide > div .offersButtons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-width: 350px;
          text-align: center;
          width: 100%;
          position: absolute;
          bottom: 25px;
          left: 50%;
          transform: translateX(-50%);
        }
        
        .input-transparent .subText {
          color: rgba(238, 239, 239, 0.5);
        }
        
        .btn-packdraw {
          background: linear-gradient(277deg, #6707c7 2.45%, #c240ff 98.78%);
          border: 1px solid #c03ffe;
          border-radius: 8px;
          box-shadow: 0 4px 0 0 #6707c7;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          padding: 11px 22px;
          text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
          text-transform: uppercase;
          width: 100%;
          cursor: pointer;
        }
        
        .roobetSideBySide > div .headerText h1 {
          font-size: 180px;
          font-weight: 800;
          line-height: 1;
          margin-bottom: 10px;
        }
        
        .roobetSideBySide > div .headerText h2 {
          text-align: center;
          font-size: 24px;
          margin-top: 0;
          margin-bottom: 20px;
        }
        
        .roobetSideBySide > div .subText {
          text-align: center;
          margin-bottom: 20px;
        }
        
        .lbtext {
          background: linear-gradient(124deg, #f4c964, #ff962e 50%, #ad5524);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media (max-width: 1200px) {
          .roobetSideBySide {
            flex-direction: column;
          }
          
          .roobetSideBySide > div {
            width: 100%;
            height: 520px;
            margin-bottom: 15px;
          }
        }

        @media (max-width: 768px) {
          .roobetSideBySide > div .headerText h1 {
            font-size: 120px;
          }
          
          .roobetSideBySide > div .headerText h2 {
            font-size: 20px;
          }
        }

        @media (max-width: 480px) {
          .roobetSideBySide > div .headerText h1 {
            font-size: 96px;
          }
          
          .roobetSideBySide > div .headerText h2 {
            font-size: 18px;
          }
          
          .primaryWrapper p {
            font-size: 18px;
          }
          
          .roobetSideBySide > div {
            height: 450px;
          }
        }
      `}</style>
      

      
<AffiliateNotification 
  isVisible={showAffiliateNotification} 
  onClose={() => setShowAffiliateNotification(false)} 
/>
{isLoading ? (
        <LoadingPage language={language} />
      ) : (
        <>
          <div className="home-bar">
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img 
      src="/icon.png" 
      alt="Roobet Logo" 
      className="home-bar-logo"
      
      style={{ paddingRight: '13px', borderRight: '1px solid #f0f8ff12' }}

    />
    <img 
      src="/roo.png" 
      alt="Roobet Logo" 
      className="home-bar-logo"
    />
    <button className="hamburger-menu" onClick={toggleMobileMenu}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
  <div className="home-bar-links">
    <a onClick={() => navigateTo('home')} title="Home">
      <Home size={24} />
    </a>
    <a onClick={() => navigateTo('leaderboard')} title="Leaderboard">
      <Award size={24} />
    </a>
    <a onClick={() => navigateTo('about')} title="About Promo Site">
      <Info size={24} />
    </a>
  </div>
  <div className="home-bar-actions">
    <div className="language-selector">
      <div className="language-selector-btn">
        <Globe size={16} />
        {language === 'en' ? 'EN' : 'IT'}
      </div>
      <div className="language-options">
        <div className="language-option" onClick={() => setLanguage('en')}>EN</div>
        <div className="language-option" onClick={() => setLanguage('it')}>IT</div>
      </div>
    </div>
  </div>
</div>
      <MobileMenu 
      isOpen={isMobileMenuOpen} 
      onClose={() => setIsMobileMenuOpen(false)} 
      navigateTo={navigateTo}
    />

          
          <button className="discord-login" aria-label="Login with Discord">
            <svg className="discord-icon" viewBox="0 0 71 55" xmlns="http://www.w3.org/2000/svg">
              <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" />
            </svg>
          </button>

          
      <button className="kick-login" aria-label="Login with Kick" onClick={() => window.open("https://kick.com/jok3r", "_blank")}>
        <img 
          src="https://logos-world.net/wp-content/uploads/2024/01/Kick-Symbol.png" 
          alt="Kick logo" 
          className="kick-icon"
        />
      </button>
    
      {currentPage === 'home' && (
            <div className="landingRoobetBonuses">
              <div className="primaryWrapper">
                <p className="golos">The most <span className="animated-gradient-text">rewarding crypto casino</span></p>
                <div className="offersButtons">
                  <div className="input-transparent"><span className="subText">Code:</span> j0k3r</div>
                  <div><button type="button" className="btn btn-packdraw" onClick={() => window.open("https://roobet.com/?ref=jok3rkick", "_blank")}>CLAIM BONUS</button></div>
                  <div className="disclaimerText">Terms may apply. Strictly 18+ only</div>
                </div>
              </div>

              <ExtraCashbackBanner />

              <div className="roobetSideBySide">
                <div className="roobetVIP">
                  <div className="headerText">
                  <h1 className="roobet golos animated-gradient">ROO</h1>
                    <h2>VIP TRANSFER PROGRAM</h2>
                  </div>
                  <div className="subText">
                  <p>Join Roobet's <span className="vip-text">VIP</span> Program for Exclusive Rewards</p>
                  </div>
                  <div className="offersButtons">
                    <div className="input-transparent"><span className="subText">Code:</span> j0k3r</div>
                    <div><button type="button" className="btn btn-packdraw" onClick={() => window.open("https://roobet.com/?ref=jok3rkick", "_blank")}>JOIN VIP</button></div>
                    <div className="disclaimerText">Terms may apply. Strictly 18+ only</div>
                  </div>
                </div>
                <div className="roobetLB">
                  <div className="headerText">
                    <h1 className="lbtext golos">5K</h1>
                    <h2>LEADERBOARD</h2>
                  </div>
                  <div className="subText">
                    <p>Compete in Jok3r's Weekly $5,000 Wager Race</p>
                  </div>
                  <div className="offersButtons">
                    <div className="input-transparent"><span className="subText">Code:</span> j0k3r</div>
                    <div><button type="button" className="btn btn-packdraw" onClick={() => window.open("https://roobet.com/?ref=jok3rkick", "_blank")}>JOIN RACE</button></div>
                    <div className="disclaimerText">Terms may apply. Strictly 18+ only</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentPage === 'leaderboard' && <Leaderboard />}
          {currentPage === 'about' && <AboutPage />}
          {currentPage === 'terms' && <TermsAndConditions />}
          <Footer navigateTo={navigateTo} />
          {showCookiePopup && <CookiePopup onAccept={acceptCookies} />}
          <NewsletterNotification
            isVisible={showNewsletterNotification}
            onClose={() => setShowNewsletterNotification(false)}
            onSubscribe={handleNewsletterSubscribe}
            />
        </>
      )}
    </>
  );
};

ReactDOM.render(<RoobetApp />, document.getElementById('root'));

export default RoobetApp;